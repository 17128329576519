<template>
  <div
    ref="trustpilot"
    class="trustpilot-widget"
    :data-businessunit-id="businessunitId"
    :data-template-id="templateId"
    :data-locale="locale"
    :data-style-height="styleHeight"
    :data-style-width="styleWidth"
    :data-theme="theme"
    :data-tags="tags"
    :data-schema-type="schemaType"
    :data-stars="stars"
    :data-font-family="fontFamily"
  >
    <a
      :href="truspilotPageURL"
      target="_blank"
      rel="noopener"
    >Trustpilot</a>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  businessunitId: {
    type: String,
    default: '4bdc32d400006400050599a4',
  },
  businessunitName: {
    type: String,
    default: 'www.unbiased.co.uk',
  },
  templateId: {
    type: String,
    default: null,
  },
  locale: {
    type: String,
    default: 'en-GB',
  },
  styleHeight: {
    type: String,
    default: 'auto',
  },
  styleWidth: {
    type: String,
    default: '100%',
  },
  theme: {
    type: String,
    default: 'light',
  },
  tags: {
    type: String,
    default: null,
  },
  schemaType: {
    type: String,
    default: null,
  },
  stars: {
    type: String,
    default: null,
  },
  fontFamily: {
    type: String,
    default: 'Lato',
  },
})

const truspilotPageURL = computed(() => {
  const locale = props.locale?.split('-')[0]
  const prefix = (locale !== 'en' ? (locale + '.') : '')

  return `https://${prefix}trustpilot.com/review/${props.businessunitName}`
})
const el = templateRef<HTMLElement>('trustpilot')
const { loadFromElement } = useScript({
  src: 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
  referrerpolicy: false,
  crossorigin: false,
}, {
  trigger: useScriptTriggerElement({
    trigger: 'visible',
    el,
  }),
  use: () => window.Trustpilot,
})
onMounted(async () => {
  await nextTick()

  if (el.value) {
    loadFromElement(el.value)
  }
})
</script>
